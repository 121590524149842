import React, { useState, useEffect } from "react";
import {
  BiDollar,
  BiBuilding,
  BiFile,
  BiMap,
  BiCheckCircle,
  BiCalculator,
  BiCopy
} from "react-icons/bi";
import { fetchFilterHome } from "../../integration/filters/home/fetchFilterHome";
import { FaMapMarkedAlt, FaShareAlt, FaRegHeart } from "react-icons/fa"; // Importando o ícone de Google Maps
import { AiOutlineLoading } from "react-icons/ai";
import { formatToBRL } from "brazilian-values";
import { BsPlusLg } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import { fetchSalvarFavorito } from "../../integration/salvar_favoritos/fetchSalvarFavorito";
import { fetchListarFiltros } from "../../integration/listar_filtros/fetchListarFiltros";
import { fetchHomeImoveis } from "../../integration/home_imoveis/fetchHomeImoveis";
import CardCalculadora from "../../components/calculadora/index";
import { fetchDeletarFiltro } from "../../integration/deletar_filtro/fetchDeletarFiltro";

import "react-toastify/dist/ReactToastify.css";

const PropertyDetails = ({ tipovisao, onSelect }) => {
  let tipocoluna = 2;

  if (tipovisao === "mobile") {
    tipocoluna = 1;
  }

  // Adicionando a prop onSelect
  const [selectedFiltroId, setSelectedFiltroId] = useState(null);
  const [filtros, setFiltros] = useState([]);
  const [imoveisIds, setImoveisIds] = useState([]);
  const [dadosCalculadora, setDadosCalculadora] = useState(null);

  const [isultimosimoveis, setIsUltimosimoveis] = useState(true);

  // Função para buscar filtros ao montar o componente
  useEffect(() => {
    const fetchFiltros = async () => {
      setLoading(true);
      try {
        const resp = await fetchListarFiltros();

        // Verifica se resp tem algum valor e ajusta aparecerfiltro
        setAparecerfiltro(resp.length > 0);
        setFiltros(resp);
        setLoading(false);
      } catch (erro) {
        setAparecerfiltro(false);
        //   setError("Erro ao buscar filtros");
        setLoading(false);
      }
    };
    fetchFiltros();
    funcHomeImoveis();
  }, []);

  // Função chamada quando o filtro é alterado
  const handleChange = async (event) => {
    const selectedId = parseInt(event.target.value);
    setSelectedFiltroId(selectedId);

    // Chama a função onSelect se estiver definida
    if (typeof onSelect === "function") {
      onSelect(selectedId);
    }
  };

  // Disparar busca sempre que um filtro é selecionado
  useEffect(() => {
    if (selectedFiltroId) {
      funcFilterHome();
    }
  }, [selectedFiltroId]);

  const [error, setError] = useState("");
  const [success, setSuccess] = useState([]);
  const [copyMessage, setCopyMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1); // Estado para a página atual
  const itemsPerPage = 4; // Número de itens por página
  const [loading, setLoading] = useState(true); // Estado para controle de carregamento
  const [msgErro, setMsgErro] = useState(false); // Estado para controle de carregamento
  const [aparecerfiltro, setAparecerfiltro] = useState(false); // Estado para controle de carregamento

  useEffect(() => {
    // Rolar para o topo quando a página mudar
    window.scrollTo(0, 0);
  }, [currentPage]);

  const handleCopy = (text) => {
    if (text) {
      navigator.clipboard.writeText(text).then(
        () => {
          setCopyMessage("Texto copiado!");
          setTimeout(() => setCopyMessage(""), 2000);
        },
        () => {
          setCopyMessage("Falha ao copiar texto.");
          setTimeout(() => setCopyMessage(""), 2000);
        }
      );
    } else {
      setCopyMessage("Texto não disponível para cópia.");
      setTimeout(() => setCopyMessage(""), 2000);
    }
  };

  // Função para buscar os ultimos 6 imoveis
  async function funcHomeImoveis() {
    setLoading(true);
    setSuccess([]);
    try {
      const resp = await fetchHomeImoveis(); // Passar os IDs de imóveis para a API
      setSuccess(resp);
      setError("");
      setMsgErro(false);
      setIsUltimosimoveis(true);
    } catch (erro) {
      setMsgErro(true);
      setSuccess([]);
      setError(erro.message);
      setIsUltimosimoveis(false);
    } finally {
      setLoading(false);
    }
  }

  // Função para buscar os imóveis com base no filtro selecionado
  async function funcFilterHome() {
    setLoading(true);
    setSuccess([]);
    try {
      const selectedFiltro = filtros.find(
        (filtro) => filtro.fil_id === selectedFiltroId
      );
      if (selectedFiltro) {
        setImoveisIds(selectedFiltro.fil_id_imoveis); // Atualizar os IDs de imóveis baseados no filtro selecionado
        const resp = await fetchFilterHome(selectedFiltro.fil_id_imoveis); // Passar os IDs de imóveis para a API
        setSuccess(resp);
        setError("");
        setMsgErro(false);
        setIsUltimosimoveis(false);
      }
    } catch (erro) {
      setMsgErro(true);
      setSuccess([]);
      setError(erro.message);
    } finally {
      setLoading(false);
    }
  }

  const openGoogleMaps = (address) => {
    if (address) {
      const encodedAddress = encodeURIComponent(address);
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`,
        "_blank"
      );
    }
  };

  // Calcular os índices de início e fim para a página atual
  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentItems = success.slice(indexOfFirstItem, indexOfLastItem);

  // Função para mudar de página
  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  // Total de páginas
  const totalPages = Math.ceil(success.length / itemsPerPage);

  const [notification, setNotification] = useState("");

  const handleShareClick = (bas_numero_imovel) => {
    const link = `https://venda-imoveis.caixa.gov.br/sistema/detalhe-imovel.asp?hdnOrigem=index&hdnimovel=${bas_numero_imovel.replace(
      /Número do imóvel: |-/g,
      ""
    )}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copiado para a área de transferência!");
      })
      .catch(() => {
        toast.error("Erro ao copiar o link.");
      });
  };

  const handleHeartClick = async (property) => {
    let idimovel = property.bas_numero_imovel;

    if (!idimovel) {
      toast.error("Erro ao favoritar");
    } else {
      try {
        await fetchSalvarFavorito(idimovel);
        toast.success(`Imóvel ${property.bas_title} Favoritado`);
      } catch (erro) {
        toast.error(erro);
      }
    }
  };

  // Função que é chamada ao clicar na calculadora
  const handleClickCalculator = (property) => {
    // Envia os dados da propriedade para o CardCalculadora
    setDadosCalculadora(property);
  };

  const handleDelete = async (filtroId) => {
    // Função para excluir o filtro
    if (filtroId) {
      let idfilter = filtroId;

      try {
        const resp = await fetchDeletarFiltro(idfilter);
        window.location.reload(); // Recarregar a página após 3 segundos
      } catch (erro) {
        setError("Erro ao deletar filtros");
      }
    } else {
      setError("Nenhum filtro selecionado para excluir.");
    }
  };

  return (
    <>
      <ToastContainer /> {/* Container para exibir notificações */}
      {loading ? (
        <div className="flex justify-center items-center w-full bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]">
          <AiOutlineLoading className="animate-spin text-[#dda219] text-4xl" />
        </div>
      ) : (
        <>
          <>
            {dadosCalculadora && (
              <>
                <div
                  className={`grid grid-cols-1 gap-2 w-full [ bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]`}
                >
                  <button
                    type="submit"
                    className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] mt-4 py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
                    onClick={() => setDadosCalculadora(null)} // Corrigi aqui para ser uma função
                  >
                    Fechar Calculadora
                  </button>
                </div>
                <CardCalculadora
                  property={dadosCalculadora}
                  tipovisao={tipovisao}
                />
              </>
            )}
          </>
          {!dadosCalculadora && (
            <>
              {aparecerfiltro && (
                <div className="p-4 w-full">
                  <h2 className="text-lg font-semibold mb-5">
                    Escolha um Filtro
                  </h2>
                  {error && <div className="text-red-500 mb-2">{error}</div>}{" "}
                  {/* Exibe mensagem de erro, se houver */}
                  <div className={`grid grid-cols-1 gap-2`}>
                    <select
                      value={selectedFiltroId || ""}
                      onChange={handleChange}
                      className="w-full p-3 border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-500 transition duration-200"
                    >
                      <option value="" disabled>
                        Selecione um filtro
                      </option>
                      {filtros.map((filtro) => (
                        <option key={filtro.fil_id} value={filtro.fil_id}>
                          {filtro.fil_nome}
                        </option>
                      ))}
                    </select>
                    <button
                      onClick={() => handleDelete(selectedFiltroId)}
                      className="mt-2 p-2 bg-gradient-to-tr border-2 border-b-slate-300 from-[#c8ae74] to-[#c8ae74]  text-white rounded-md  transition duration-200"
                    >
                      Deletar Filtro
                    </button>
                  </div>
                </div>
              )}

              {isultimosimoveis && (
                <div
                  className={`grid grid-cols-${tipocoluna} p-2 gap-2 w-full   bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]`}
                >
                  <p className="text-gray-400 my-5 font-semibold text-[15px]">
                    Imóveis atualizados recentemente
                  </p>
                </div>
              )}

              <div
                className={`grid grid-cols-${tipocoluna} p-2 gap-2 w-full   bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]`}
              >
                {currentItems.map((property) => (
                  <div
                    key={property.bas_id}
                    className="w-full  bg-white border border-gray-300 shadow-md rounded-lg flex flex-col overflow-hidden"
                  >
                    <div className="flex flex-col h-full p-6">
                      <div className="grid grid-cols-1 p-1 mb-2 rounded-md bg-gray-200">
                        <p className="text-gray-600 font-semibold text-[15px]">
                          {property.bas_modalidade_venda} -{" "}
                          {property.bas_estado}
                        </p>
                      </div>

                      <div className="flex-grow flex items-center justify-center mb-4">
                        <img
                          className="w-full h-40 object-cover rounded-md border border-gray-200"
                          src={property.bas_image}
                          alt={property.bas_title}
                        />
                      </div>

                      {notification && (
                        <div className="notification bg-green-100 p-2 my-5 rounded">
                          {notification}
                        </div>
                      )}
                      <div className="flex flex-col flex-grow">
                        <div className="flex justify-between items-center mb-2">
                          <h1 className="font-extrabold text-gray-700 text-[15px]">
                            {property.bas_title}
                          </h1>
                          {property.bas_desconto && (
                            <p className="text-red-500 font-bold text-[13px] bg-red-100 px-2 py-1 rounded-md">
                              {property.bas_desconto}%
                            </p>
                          )}
                        </div>
                        <div className="flex justify-between items-center mb-2">
                          <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                            <BiBuilding className="text-yellow-700 mr-2" />
                            <p>
                              <span className="font-semibold">
                                Tipo de imóvel:
                              </span>{" "}
                              {property.bas_tipo}
                            </p>
                          </div>
                          <div>
                            <div className="flex">
                              <FaShareAlt
                                className="text-black cursor-pointer"
                                onClick={() =>
                                  handleShareClick(property.bas_numero_imovel)
                                }
                              />

                              <FaRegHeart
                                className="text-red-700 ml-3 cursor-pointer"
                                onClick={() => handleHeartClick(property)}
                              />
                            </div>
                          </div>
                        </div>

                        <hr className="border-t border-gray-300 my-2" />
                        <div className="flex justify-between items-center mb-2 text-gray-600 text-[15px]">
                          <span className="flex items-center">
                            <BiDollar className="text-yellow-700 mr-2" />
                            Valor de avaliação:
                          </span>
                          <p className="line-through text-gray-500 text-[12px]">
                            {formatToBRL(property.bas_valor_avaliacao) ||
                              "Não disponível"}
                          </p>
                        </div>
                        <div>
                          <div className="flex justify-between items-center mb-2 text-gray-600 text-[15px]">
                            <span className="flex items-center">
                              <BiDollar className="text-yellow-700 mr-2" />
                              Valor mínimo de venda:
                            </span>
                            <div className="flex items-center">
                              <p className="font-bold text-green-600 text-[12px]">
                                {formatToBRL(property.bas_valor_minimo_venda) ||
                                  "Não disponível"}
                              </p>
                              {/* Adiciona o onClick no ícone da calculadora */}
                              <BiCalculator
                                className="text-gray-500 ml-2 text-[15px] cursor-pointer"
                                onClick={() => handleClickCalculator(property)} // Função passada corretamente
                              />
                            </div>
                          </div>
                        </div>
                        <hr className="border-t border-gray-300 my-2" />
                        <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                          <BiFile className="text-yellow-700 mr-2" />
                          <p>
                            <span className="font-semibold">Matrícula(s):</span>{" "}
                            <a
                              href={property.bas_matricula || "#"}
                              target="_blank"
                              rel="noopener noreferrer"
                              className="text-blue-600 hover:underline"
                            >
                              Ver Matrícula
                            </a>
                          </p>
                        </div>
                        <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                          <BiFile className="text-yellow-700 mr-2" />
                          <p>
                            <span className="font-semibold">Comarca:</span>{" "}
                            {property.bas_estado}
                          </p>
                        </div>
                        <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                          <BiFile className="text-yellow-700 mr-2" />
                          <p>
                            <span className="font-semibold">
                              Número do imóvel:
                            </span>{" "}
                            {property.bas_numero_imovel
                              ? property.bas_numero_imovel.replace(
                                  "Número do imóvel: ",
                                  ""
                                )
                              : "Número não disponível"}
                          </p>
                          <button
                            onClick={() =>
                              handleCopy(property.bas_numero_imovel || "")
                            }
                            className="ml-2 p-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                          >
                            <BiCopy />
                          </button>
                        </div>
                        {copyMessage && (
                          <p className="text-green-500">{copyMessage}</p>
                        )}
                        <hr className="border-t border-gray-300 my-2" />
                        <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                          <BiCheckCircle className="text-yellow-700 mr-2" />
                          <p className="font-semibold">
                            {property.bas_aceita_financiamento}
                          </p>
                        </div>

                        <hr className="border-t border-gray-300 my-2" />
                        <div className="flex items-center text-gray-600 text-[15px] mb-2">
                          <BiMap className="text-yellow-700 mr-2" />
                          <div className="flex items-center">
                            <p className="font-semibold">Endereço:</p>
                            <FaMapMarkedAlt
                              className="text-yellow-700 text-[20px] cursor-pointer ml-2"
                              onClick={() =>
                                openGoogleMaps(property.bas_address)
                              }
                            />
                          </div>
                        </div>
                        <p className="text-gray-600 text-[15px]">
                          {property.bas_address || "Endereço não disponível"}
                        </p>
                      </div>

                      <button
                        type="submit"
                        className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] mt-4 py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
                        onClick={() =>
                          window.open(
                            `https://venda-imoveis.caixa.gov.br/sistema/detalhe-imovel.asp?hdnOrigem=index&hdnimovel=${property.bas_numero_imovel.replace(
                              /Número do imóvel: |-/g,
                              ""
                            )}`,
                            "_blank"
                          )
                        }
                      >
                        <BsPlusLg className="font-bold" />
                        Detalhes
                      </button>
                    </div>
                  </div>
                ))}
              </div>

              {success.length > 0 && (
                <div className="flex items-center justify-center col-span-2 mt-8 my-10">
                  <div className="flex items-center justify-between w-full text-gray-600 dark:text-gray-400 bg-gray-100 rounded-lg dark:bg-gray-600 max-w-[128px] mx-2">
                    <button
                      type="button"
                      onClick={() => paginate(currentPage - 1)}
                      disabled={currentPage === 1}
                      className="inline-flex items-center justify-center h-8 px-1 w-6 bg-gray-100 rounded-s-lg dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-800"
                    >
                      <svg
                        className="w-2 h-2 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="M5 1 1 5l4 4"
                        />
                      </svg>
                      <span className="sr-only">Página anterior</span>
                    </button>
                    <span className="flex-shrink-0 mx-1 text-sm font-medium space-x-0.5 rtl:space-x-reverse">
                      {currentPage} de {totalPages}
                    </span>

                    <button
                      type="button"
                      onClick={() => paginate(currentPage + 1)}
                      disabled={currentPage === totalPages}
                      className="inline-flex items-center justify-center h-8 px-1 w-6 bg-gray-100 rounded-e-lg dark:bg-gray-600 hover:bg-gray-200 dark:hover:bg-gray-800 focus:outline-none focus:ring-2 focus:ring-gray-200 dark:focus:ring-gray-800"
                    >
                      <svg
                        className="w-2 h-2 rtl:rotate-180"
                        aria-hidden="true"
                        xmlns="http://www.w3.org/2000/svg"
                        fill="none"
                        viewBox="0 0 6 10"
                      >
                        <path
                          stroke="currentColor"
                          strokeLinecap="round"
                          strokeLinejoin="round"
                          strokeWidth="2"
                          d="m1 9 4-4-4-4"
                        />
                      </svg>
                      <span className="sr-only">Próxima página</span>
                    </button>
                  </div>
                </div>
              )}
            </>
          )}
        </>
      )}
    </>
  );
};

export default PropertyDetails;
