import React, { useState, useEffect } from "react";
import {
  BiDollar,
  BiBuilding,
  BiFile,
  BiMap,
  BiCheckCircle,
  BiCalculator,
  BiCopy,
} from "react-icons/bi";
import { FaMapMarkedAlt, FaShareAlt, FaRegHeart } from "react-icons/fa"; // Importando o ícone de Google Maps
import { AiOutlineLoading } from "react-icons/ai";
import { formatToBRL } from "brazilian-values";
import { BsPlusLg } from "react-icons/bs";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { fetchSalvarFavorito } from "../../integration/salvar_favoritos/fetchSalvarFavorito";
import CardCalculadora from "../../components/calculadora/index";
import Modal from "../../components/Modal/Modal"; // Importe o componente Modal

const PropertyDetails = ({ dados, versao, versaoreversecalc }) => {
  let tipocoluna = 1;

  if (versao === "mobile") {
    tipocoluna = 1;
  }

  let tipocoluna2 = 2;

  if (versao === "mobile") {
    tipocoluna2 = 1;
  }

  const [success, setSuccess] = useState([]); // Inicialize como array vazio
  const [copyMessage, setCopyMessage] = useState("");
  const [currentPage, setCurrentPage] = useState(1);
  const itemsPerPage = 4;
  const [loading, setLoading] = useState(true);
  const [dadosCalculadora, setDadosCalculadora] = useState(null);
  const [isscalculator, setIscalculator] = useState(false);

  useEffect(() => {
    if (dados) {
      // Atualize o estado com os dados recebidos
      setSuccess([dados]); // Aqui assumimos que `dados` é um único objeto
      setLoading(false);
    }
    window.scrollTo(0, 0);
  }, [dados]);

  const handleCopy = (text) => {
    if (text) {
      navigator.clipboard.writeText(text).then(
        () => {
          setCopyMessage("Texto copiado!");
          setTimeout(() => setCopyMessage(""), 2000);
        },
        () => {
          setCopyMessage("Falha ao copiar texto.");
          setTimeout(() => setCopyMessage(""), 2000);
        }
      );
    } else {
      setCopyMessage("Texto não disponível para cópia.");
      setTimeout(() => setCopyMessage(""), 2000);
    }
  };

  const openGoogleMaps = (address) => {
    if (address) {
      const encodedAddress = encodeURIComponent(address);
      window.open(
        `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`,
        "_blank"
      );
    }
  };

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;

  const currentItems = success.slice(indexOfFirstItem, indexOfLastItem);

  const paginate = (pageNumber) => setCurrentPage(pageNumber);

  const totalPages = Math.ceil(success.length / itemsPerPage);

  const [notification, setNotification] = useState("");

  const handleShareClick = (bas_numero_imovel) => {
    const link = `https://venda-imoveis.caixa.gov.br/sistema/detalhe-imovel.asp?hdnOrigem=index&hdnimovel=${bas_numero_imovel.replace(
      /Número do imóvel: |-/g,
      ""
    )}`;

    navigator.clipboard
      .writeText(link)
      .then(() => {
        toast.success("Link copiado para a área de transferência!");
      })
      .catch(() => {
        toast.error("Erro ao copiar o link.");
      });
  };

  const handleHeartClick = async (property) => {
    let idimovel = property.bas_numero_imovel;

    if (!idimovel) {
      toast.error("Erro ao favoritar");
    } else {
      try {
        await fetchSalvarFavorito(idimovel);
        toast.success(`Imóvel ${property.bas_title} Favoritado`);
      } catch (erro) {
        toast.error(erro);
      }
    }
  };

  const handleClickCalculator = (property) => {
    setIscalculator(true);
    setDadosCalculadora(property);
  };

  const handleFecharCalculator = () => {
    setIscalculator(false);
    setDadosCalculadora(null);
  };

  return (
    <>
      <ToastContainer /> {/* Container para exibir notificações */}
      {loading ? (
        <div className="flex justify-center items-center w-full bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]">
          <AiOutlineLoading className="animate-spin text-[#dda219] text-4xl" />
        </div>
      ) : (
        <>
          <>
            {/* Modal para a Calculadora */}
            {isscalculator && (
              <Modal onClose={handleFecharCalculator}>
                <CardCalculadora
                  property={dadosCalculadora}
                  tipovisao={versao}
                  acessoucel={tipocoluna2}
                />
                <button
                  className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] mt-4 py-2 rounded-2xl text-slate-100"
                  onClick={handleFecharCalculator}
                >
                  Fechar Calculadora
                </button>
              </Modal>
            )}

            {!isscalculator && !dadosCalculadora && (
              <>
                <div
                  className={`grid grid-cols-${tipocoluna} p-2 gap-2 w-full   bg-gradient-to-tl from-[#e4e4e4] to-[#e4e4e4]`}
                >
                  {currentItems.map((property) => (
                    <div
                      key={property.bas_id}
                      className="w-full  bg-white border border-gray-300 shadow-md rounded-lg flex flex-col overflow-hidden"
                    >
                      <div className="flex flex-col h-full p-6">
                        <div className="grid grid-cols-1 p-1 mb-2 rounded-md bg-gray-200">
                          <p className="text-gray-600 font-semibold text-[15px]">
                            {property.bas_modalidade_venda} -{" "}
                            {property.bas_estado}
                          </p>
                        </div>

                        <div className=" items-center justify-center mb-4">
                          <img
                            className="w-full h-40 object-cover rounded-md border border-gray-200"
                            src={property.bas_image}
                            alt={property.bas_title}
                          />
                        </div>

                        {notification && (
                          <div className="notification bg-green-100 p-2 my-5 rounded">
                            {notification}
                          </div>
                        )}
                        <div className="flex flex-col flex-grow">
                          <div className="flex justify-between items-center mb-2">
                            <h1 className="font-extrabold text-gray-700 text-[15px]">
                              {property.bas_title}
                            </h1>
                            {property.bas_desconto && (
                              <p className="text-red-500 font-bold text-[13px] bg-red-100 px-2 py-1 rounded-md">
                                {property.bas_desconto}%
                              </p>
                            )}
                          </div>
                          <div className="flex justify-between items-center mb-2">
                            <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                              <BiBuilding className="text-yellow-700 mr-2" />
                              <p>
                                <span className="font-semibold">
                                  Tipo de imóvel:
                                </span>{" "}
                                {property.bas_tipo}
                              </p>
                            </div>
                            <div>
                              <div className="flex">
                                <FaShareAlt
                                  className="text-black cursor-pointer"
                                  onClick={() =>
                                    handleShareClick(property.bas_numero_imovel)
                                  }
                                />

                                <FaRegHeart
                                  className="text-red-700 cursor-pointer ml-5"
                                  onClick={() => handleHeartClick(property)}
                                />
                              </div>
                            </div>
                          </div>

                          <hr className="border-t border-gray-300 my-2" />
                          <div className="flex justify-between items-center mb-2 text-gray-600 text-[15px]">
                            <span className="flex items-center">
                              <BiDollar className="text-yellow-700 mr-2" />
                              Valor de avaliação:
                            </span>
                            <p className="line-through text-gray-500 text-[12px]">
                              {formatToBRL(property.bas_valor_avaliacao) ||
                                "Não disponível"}
                            </p>
                          </div>
                          <div className="flex justify-between items-center mb-2 text-gray-600 text-[15px]">
                            <span className="flex items-center">
                              <BiDollar className="text-yellow-700 mr-2" />
                              Valor mínimo de venda:
                            </span>
                            <div className="flex items-center">
                              <p className="font-bold text-green-600 text-[12px]">
                                {formatToBRL(property.bas_valor_minimo_venda) ||
                                  "Não disponível"}
                              </p>
                              <BiCalculator
                                className="text-gray-500 ml-2 text-[15px] cursor-pointer"
                                onClick={() => handleClickCalculator(property)} // Função passada corretamente
                              />{" "}
                            </div>
                          </div>
                          <hr className="border-t border-gray-300 my-2" />
                          <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                            <BiFile className="text-yellow-700 mr-2" />
                            <p>
                              <span className="font-semibold">
                                Matrícula(s):
                              </span>{" "}
                              <a
                                href={property.bas_matricula || "#"}
                                target="_blank"
                                rel="noopener noreferrer"
                                className="text-blue-600 hover:underline"
                              >
                                Ver Matrícula
                              </a>
                            </p>
                          </div>
                          <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                            <BiFile className="text-yellow-700 mr-2" />
                            <p>
                              <span className="font-semibold">Comarca:</span>{" "}
                              {property.bas_estado}
                            </p>
                          </div>
                          <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                            <BiFile className="text-yellow-700 mr-2" />
                            <p>
                              <span className="font-semibold">
                                Número do imóvel:
                              </span>{" "}
                              {property.bas_numero_imovel
                                ? property.bas_numero_imovel.replace(
                                    "Número do imóvel: ",
                                    ""
                                  )
                                : "Número não disponível"}
                            </p>
                            <button
                              onClick={() =>
                                handleCopy(property.bas_numero_imovel || "")
                              }
                              className="ml-2 p-1 bg-yellow-500 text-white rounded hover:bg-yellow-600"
                            >
                              <BiCopy />
                            </button>
                          </div>
                          {copyMessage && (
                            <p className="text-green-500">{copyMessage}</p>
                          )}
                          <hr className="border-t border-gray-300 my-2" />
                          <div className="flex items-center mb-2 text-gray-600 text-[15px]">
                            <BiCheckCircle className="text-yellow-700 mr-2" />
                            <p className="font-semibold">
                              {property.bas_aceita_financiamento}
                            </p>
                          </div>

                          <hr className="border-t border-gray-300 my-2" />
                          <div className="flex items-center text-gray-600 text-[15px] mb-2">
                            <BiMap className="text-yellow-700 mr-2" />
                            <div className="flex items-center">
                              <p className="font-semibold">Endereço:</p>
                              <FaMapMarkedAlt
                                className="text-yellow-700 text-[20px] cursor-pointer ml-2"
                                onClick={() =>
                                  openGoogleMaps(property.bas_address)
                                }
                              />
                            </div>
                          </div>
                          <p className="text-gray-600 text-[15px]">
                            {property.bas_address || "Endereço não disponível"}
                          </p>

                          <button
                            type="submit"
                            className="w-full bg-gradient-to-tr border-2 border-b-slate-300 from-[#be850c] to-[#e4a81c] mt-4 py-2 rounded-2xl text-slate-100 mb-2 flex items-center justify-center"
                            onClick={() =>
                              window.open(
                                `https://venda-imoveis.caixa.gov.br/sistema/detalhe-imovel.asp?hdnOrigem=index&hdnimovel=${property.bas_numero_imovel.replace(
                                  /Número do imóvel: |-/g,
                                  ""
                                )}`,
                                "_blank"
                              )
                            }
                          >
                            <BsPlusLg className="font-bold" />
                            Detalhes
                          </button>
                        </div>
                      </div>
                    </div>
                  ))}
                </div>
              </>
            )}
          </>
        </>
      )}
    </>
  );
};

export default PropertyDetails;
